import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-error',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    styles: `
        @import "../../../../styles/variables";

        .error {
            color: $red;
        }
    `,
    template: `<small class="error">{{ error }}</small>`
})
export class ErrorComponent {
    @Input() error: string = '';
}
